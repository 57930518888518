

<template>
  <div>
    <div v-if="orderHeaderId === null || orderHeaderId === undefined || orderHeaderId === ''" > 
      <h2>Loading . . .</h2>
    </div>
    <div v-else >
      <receipt forwho="s" :orderid="orderHeaderId" :watermark="watermark"></receipt>
    </div>
  </div>
</template>


<script>
import Receipt from "./Receipt2.vue";
export default {
  props:{
    
    orderid:  String,
     
  },
  data() {
    return {
      orderHeaderId: "",
      watermark: false
    };
  },
  created() {
    this.orderHeaderId = this.orderid;
    // console.log("RECPOPUP this.orderid >>>> ",this.orderid);
    var wm = "f";

    if (wm === "f") {
      this.watermark = false;
    } else {
      this.watermark = true;
    }
  },
  mounted() {
   
  },
  components: {
    Receipt
  }
};
</script>
<style>
.page {
  width: 210mm;
  min-height: 291mm;
  padding: 4mm 4mm 2mm;
  margin: 0 auto;
  border: 1px #d3d3d3 solid;
  /* border-radius: 5px; */
  background: white;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
}
@page {
  size: A4;
  margin: 0;
}

@media print {
  .pagebreak {
    clear: both;
    page-break-after: always;
  }

  html,
  body {
    width: 210mm;
    height: 297mm;
  }
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}
</style>
