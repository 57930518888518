<template>
  <vs-card>
    <!-- ZOOM POP UP -->
    <template>
      <!-- ZOOM IMAGE POP UP -->
      <vs-popup fullscreen
        classContent="popup-example label-font "
        title="ข้อมูลสินค้าตามใบเสร็จรับเงิน"
        :active.sync="receiptPopupActive"
      >
        <div style="width:1000px; margin: 5px auto;">
          <recpopup  :key="updateKey" :orderid="orderHeaderId" ></recpopup>
        </div>
      </vs-popup>
      <!-- POP UP -->
    </template>
    <div class="px-4 py-8">
      <div class="flex justify-between mb-8">
        <div>
          <h2>ประวัติการเช่า/ซื้อ</h2>
        </div>
      </div>
      <div class="flex justify-between mb-8">
        <div>
          <h5>ชื่อปัจจุบัน : {{customer.name}} </h5>
           <h5> เบอร์โทร : {{customer.phone}}</h5>
        </div>
      </div>

      <vs-table pagination max-items="20" search class="mt-8" :data="orders">
        <template slot="thead">
          <vs-th sort-key="created" class="whitespace-no-wrap"
            >วัน/เดือน/ปี</vs-th
          >
          <vs-th sort-key="orderId" class="whitespace-no-wrap">เลข Order</vs-th>
          <vs-th sort-key="name" class="whitespace-no-wrap">ผู้เช่า</vs-th>
          <vs-th sort-key="email" class="whitespace-no-wrap">ผู้รับเงิน</vs-th>
          <vs-th sort-key="orderType" class="whitespace-no-wrap">ประเภท</vs-th>
          <vs-th sort-key="paymentType" class="whitespace-no-wrap"
            >วิธีชำระ</vs-th
          >
          <vs-th sort-key="orderTotalPrice" class="whitespace-no-wrap"
            >ยอดรวม</vs-th
          >
        </template>
        <template slot-scope="{ data }">
          <vs-tr :key="index" v-for="(tr, index) in data">
            <vs-td>{{ format_ddmmyyy(tr.createDateTime) }}</vs-td>
            <vs-td>
            <vs-button
              :href="rediretToReceipt(tr)"
              target="blank"
              style="padding:0; font-size:11px; color: #2372CE; background-color: white !important; border: 0 white solid !important; font-weight:bold;"
            >{{ tr.orderNumber }}</vs-button>
                <br />
                     <span v-if="tr.description === 'เช่าสินค้า'" >({{ tr.branchName }})</span></vs-td>
            <vs-td>{{ tr.customerName }}</vs-td>
            <vs-td>{{ tr.createBy }}</vs-td>
            <vs-td>
              <vs-button @click="toPopup(tr)"
                :color="tr.description == 'เช่าสินค้า' ? 'success' : 'warning'"
                size="small"
                style="width: 100px"
                class="rounded-full text-base"
                >{{ tr.description }}</vs-button
              > 
              <br>
              <div @click="toPopup(tr)" class="flex justify-start pt-2 pl-4 "  style="cursor:pointer; color: blue; font-size : 0.8rem;" >ดูสินค้าในใบเสร็จ</div>
            </vs-td>
            <vs-td>{{ tr.paymentMethod }}</vs-td>
            <vs-td>{{
              tr.grandTotal.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
            }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
  </vs-card>
</template>

<script>
import shapeFormat from '@/mixins/shapeFormat'
import recpopup from '@/views/pages/recpopup.vue'

export default {
  mixins : [shapeFormat],
  data() {
    return {
      updateKey: 0,
      receiptPopupActive: false,
      orderHeaderId: "",
      customer: null,
      customerId: "",
      orders: []
    };
  },
  components: {
    recpopup
  },
  computed: {
     //  *** BEGIN : 2021-11-11 : Search By branches
    getCurrentBranch(){
      return this.$store.getters["auth/currentBranch"];
    },
  },
  watch: {
    async getCurrentBranch(){
      await this.fetchOrderHistory();
    },
  },
  methods: {
    toPopup(tr){
      this.updateKey = this.updateKey +1;

      this.orderHeaderId = tr.orderMergingId;
      this.receiptPopupActive = true;

      

    },


    rediretToReceipt(tr) {
      return "/backoffice/order-detail/" + tr.orderMergingId;
    },
   
    async fetchOrderHistory() {
      const state = this.$store.state;
      const res = await this.$http.post(

        `${state.apiURL}/api/customer/customer-history`,
        {
          customerId: this.customerId,
          branchId: this.getCurrentBranch.branchId
        },
        state.auth.apiHeader
      );

      this.orders = res.data.orderHistory;
       this.customer = res.data.customer;


      // request.then(res => {
      //   console.log(res);
      //   res.forEach(item => {
      //     item.createDateTime = this.$dateFormat(item.createDateTime);
      //   });

      //   this.orders = res;
      // });
    }
  },
  mounted() {
    console.log(">>> customer rental history");
    this.customerId = this.$route.params.customerId;
    this.fetchOrderHistory();
    console.log(">>> customer rental history",this.orders);
  }
};
</script>

<style scoped>
.vs-popup {
  width: 100% !important;
}

</style>
